<template>
    <div>
        <div class="card col-lg-12 col-md-12 col-sm-12" style="min-height: 20em;">
           <div>
                <h4 class="my-4 font-weight-bold">Liste des rôles</h4>
            </div>        
            <div class="d-flex mb-4" style="width : 100%; align-items: flex-start">
                <div>
                    <b-button v-b-modal.roleForm  variant="danger" style="margin-right:20px; min-width: 150px; height:45px;" class="py-3 px-4 d-flex justify-content-center align-items-center">
                        <i class="fa fa-plus" aria-hidden="true"></i> 
                        Ajouter un nouveau role
                    </b-button>
                </div>
                <div>
                    <b-button variant="secondary" style="min-width: 150px; height:45px;" class="py-3 px-4 d-flex justify-content-center align-items-center">
                        <i class="ik ik-rotate-ccw"></i> 
                        Mettre à jour
                    </b-button>
                </div>
            </div> 
            <b-container>
                <b-overlay :show="showOverlayTable" rounded="sm">
                    <div class="mt-1 pr-2 w-100 d-flex justify-content-around flex-wrap" style="height: 40em; overflow-y: auto; overflow-x: hidden;">
                        <b-table sticky-header="90%" striped hover responsive :items="roles" :fields="[
                                { key: 'index', label: 'N°' },
                                { key: 'titre', label: 'Titre', sortable: true },
                                { key: 'permissions', label: 'permissions', sortable: true },
                                { key: 'action', label: 'Actions' },
                            ]" style="min-height: 20em "> 
                            <template #cell(index)="data">{{ ++data.index }}</template>
                            <template #cell(titre)="data">{{ data.item.libelleRole }}</template>
                            <template #cell(permissions)="data" >
                                <span  >
                                    <b-badge v-for="(permission, i) in data.item.privileges" :key="i" class="m-1" pill variant="primary">{{ permission }}</b-badge>
                                </span>
                            </template>
                            <template #cell(action)="row">
                                <div style = "display: flex">
                                    <b-button size="sm" variant ="none"  @click.prevent="roleDetails(row.item)"><i style="color: blue" class="fa fa-eye"></i></b-button>
                                    <b-button size="sm" variant ="none"  @click.prevent="editRole(row.item)"><i style="color: green" class="ik ik-edit-2"></i></b-button>
                                    <b-button size="sm" variant ="none" @click.prevent="deleteRole(row.item)" class="ml-2"><i style="color: #f5365c" class="fa fa-trash"></i></b-button>
                                </div>  
                            </template>
                        </b-table>
                    </div>
                </b-overlay>
            </b-container>
        </div>
        <!--MODAL POUR AJOUTER UN ROLE -->
        <b-modal id="roleForm" size="lg" title="Formulaire d'Ajout d'un role" hide-footer no-close-on-backdrop>
            
            <role-form  @roleAdded="addedRole" @roleEdited="roleEditionSuccessful" :editRole="role" :action="action"/>
        </b-modal>
    </div>
</template>
<script>
import RoleForm from '@/views/gestion-utilisateurs/roles/RoleForm.vue'
export default {
    name:'users',
    components:{
        RoleForm
    },
      data: () => ({
          role:null,
          action:"add",
          showOverlayTable: true,
            roles:[

            ],
      }),
      methods: {
          addedRole(role){        
              this.$bvModal.hide('roleForm')
              this.getRoles();
              //this.roles.unshift(role);
          },
          roleEditionSuccessful(role){ 
              this.$bvModal.hide('roleForm')
              this.getRoles();
          },
          roleDetails(role){
            console.log('role', role);
            this.$router.push({ name: 'details-role', params: { id : role.idRole } })
          },
          editRole(role){
              console.log('role to edit', role)
              this.role=role;
              this.action="edit";
            this.$bvModal.show('roleForm')
          },
          deleteRole(role){
              App.confirm(`Voullez vous vraiment supprimer le role " <b>${role.libelleRole}</b> " ?`, { confirm: () => {
                axios.delete(`entreprises/roles/${role.idRole}`).then(response => {
                    if (!response.success) {
                        return App.notifyError(response.message)
                    }
                    this.getRoles();
                    return App.notifySuccess(response.message)
                })
            }})
          },
          getRoles() {
            this.showOverlayTable = true;
            axios.get('/entreprises/roles').then(response => response.result || []).then(result => {
                this.roles = result
                this.resetData();
                this.showOverlayTable = false
            })
         },
         resetData(){
             this.role=null;this.action="add"
         },
      },
      mounted(){
          this.getRoles();
        /*setTimeout(() => {
            this.showOverlayTable= false;
        }, 2000)*/

    },
}
</script>
<style scoped>
    .icon-list-table{
        width: 4em;
         height: 4em; 
         background: red;
         position: absolute;
         top:-5%;
    }
    .text-list{
        position: absolute;
        top: 5px;
        left: 8%;
    }
    .actions-buttons-group{
        position: absolute;
        top: 5em;
        left: 8%
    }

</style>