<template>
    <div>
      <b-overlay :show="showOverlay" rounded="sm">
        <div style="height: 50em; overflow-y: auto; overflow-x: hidden;">
          <b-row>
            <b-col>
              <b-form-group
                label="Libelle du role"
                
              >
                <b-form-input
                  v-model="role.libelle"
                  placeholder=""
                  trim
                    :class="{'is-invalid': !required}"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="my-auto">
            </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-between">
              <div class="mt-4">
                  <paginator2  hr="top" :offset="offset" :total="allPrivileges.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" />  
              </div>
              <div>
                <span>Rechercher : </span><b-form-input v-model="search"></b-form-input>
              </div>

              <div><b-form-group class="mt-4">
                <b-form-checkbox class="font-weight-bold" :disabled="submitted" v-model="allSelected" @change="toggleAll">{{ allSelected ? 'Tout deselectionner' : 'Tout selectionner' }}</b-form-checkbox>
              </b-form-group></div>
            </div>
            <div class="mb-0 mt-2" >
              
              <b-table-simple :sortable="true" class="mb-1 p-0" hover small responsive style="border: 1px solid black!important">
                <b-tbody>
                  <b-tr>
                      <b-th class="border m-0 text-center" style="width: 9%"></b-th>
                      <b-th class="border m-0 text-center" style="width: 15%">Code</b-th>
                      <b-th class="border m-0 text-center" style="width: 25%">Libellé</b-th>
                      <b-th class="border m-0 text-center" >Description</b-th>              
                  </b-tr>
                    <b-tr v-for="privilege in items" :key="privilege.idPrivilege">
                        <b-td class="border text-center">
                            <b-form-checkbox v-model="privilege.selected"></b-form-checkbox>   
                        </b-td>
                        <b-td class="border px-2">
                            <span class="d-inline-block w-100 text-center font-weight-bold">{{ privilege.codePrivilege }}</span>
                        </b-td>
                        <b-td class="border  px-2">
                            <span class="d-inline-block w-100 text-center">{{ privilege.libellePrivilege }}</span>
                        </b-td>
                        <b-td class="border px-2">
                            <span class="d-inline-block w-100 text-center">{{ privilege.description }}</span>
                        </b-td>
                    </b-tr>
                            
                      </b-tbody>
              </b-table-simple>
              <paginator hr="top" :offset="offset" :total="allPrivileges.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" />      
            </div>
          </b-col>
        </b-row>  
        </div>
            
   
        <div class="float-right mt-2">
          <b-button :disabled="submitted" @click.prevent="submitRoleForm" variant="danger">{{$t('data.cite_valider_cite')}}<b-spinner small v-if="submitted" style="margin-left: 5px;"></b-spinner> </b-button>
        </div>
    </b-overlay>
    </div>
    
</template>
<script>
import SearchForm from "@/components/parts/SearchForm.vue";
const php = require("phpjs");
export default {
  name: "user-form",
  components: { SearchForm},
  data: () => ({
    currentPage: 1,
    perPage: 20,
    search: null,
    submitted:false,
    required: false,
    allSelected: false,
    showOverlay:true,
    limiter : 20,
    role:{
      libelle: '',
      privileges:[],
    },
    allPrivileges:[],
    truePrivileges:[]
  }),
  watch: {
    search(value) {
      this.allPrivileges = !php.empty(value)
          ? this.truePrivileges.filter(elt =>
              elt.codePrivilege.toLowerCase().includes(value.toLowerCase())
          )
          : this.truePrivileges;
    }
  },
  computed:{
    selectedPrivileges() {
        return this.allPrivileges.filter(elt => elt.selected).map(elt => elt.codePrivilege)
    },
    /**
         * Elements affichés avec prise en charge de la pagination
         */
      items() { 
          return php.array_slice(this.allPrivileges, this.offset, this.perPage) 
      },
      offset() {
          return (this.currentPage * this.perPage) - this.perPage
      }
  },
  props: {
    action: {type: String, required: true, default: "add"},
    editRole:{ type:Object}
  },

  methods: {
    toggleAll(checked) {
            this.allPrivileges = this.allPrivileges.map(elt => ({...elt, selected: checked}))
    },
    submitRoleForm(){
      if (!this.selectedPrivileges.length) {
                return App.error("Veuillez selectionner au moins un privilège")
      }
      if (php.empty(this.role.libelle)) {
          return App.error("Veuillez entrer le libellé du role")
      }
      this.role.privileges=this.selectedPrivileges
      this.submitted = true


      if(this.action=="add"){
        console.log('entrée ajout', this.role);
          axios.post('/entreprises/roles', this.role).then(response => {
          this.$emit("roleAdded", response.result);
          this.submitted = false
            App.alertSuccess(response.message)
        }).catch(error => {
          this.submitted = false
          App.notifyError(error.message)
        })
      }
      if(this.action=="edit"){
        console.log('entrée édition ', this.role);
        axios.put('entreprises/roles/'+this.editRole.idRole, this.role).then(response => {
          this.$emit("roleEdited", response.result);
            this.submitted = false
              App.alertSuccess(response.message)
        }).catch(error => {
            this.submitted = false
            App.notifyError(error.message)
        })
      }
    },
    async getPrivileges(){
        try {
            const allPrivileges = await axios.get("constants/privileges").then(response => response.result);
            this.allPrivileges = this.truePrivileges = allPrivileges.map(elt => ({...elt, selected: false}))
        } catch (error) {
            notif.error(error.message);
        }

        this.showOverlay=false;
    },
    
    
  
  },
  async mounted() {
    await this.getPrivileges();

    if(this.editRole){
        this.role.libelle=this.editRole.libelleRole;   
        this.allPrivileges = this.allPrivileges.map(elt => ({...elt, selected: this.editRole.privileges.includes(elt.codePrivilege)}))
    }
  },

};
</script>
<style scoped>
  .example-form-control{
    height: auto;
    min-height: 35px;
    border: 1px solid #eaeaea;
    padding: 0 10px;
    background-color: #fff;
    font-size: 13px;
    border-radius: 4;
    margin-left:2em;

  }


      .leloader,
.leloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.leloader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
