var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card col-lg-12 col-md-12 col-sm-12",staticStyle:{"min-height":"20em"}},[_vm._m(0),_c('div',{staticClass:"d-flex mb-4",staticStyle:{"width":"100%","align-items":"flex-start"}},[_c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.roleForm",modifiers:{"roleForm":true}}],staticClass:"py-3 px-4 d-flex justify-content-center align-items-center",staticStyle:{"margin-right":"20px","min-width":"150px","height":"45px"},attrs:{"variant":"danger"}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v(" Ajouter un nouveau role ")])],1),_c('div',[_c('b-button',{staticClass:"py-3 px-4 d-flex justify-content-center align-items-center",staticStyle:{"min-width":"150px","height":"45px"},attrs:{"variant":"secondary"}},[_c('i',{staticClass:"ik ik-rotate-ccw"}),_vm._v(" Mettre à jour ")])],1)]),_c('b-container',[_c('b-overlay',{attrs:{"show":_vm.showOverlayTable,"rounded":"sm"}},[_c('div',{staticClass:"mt-1 pr-2 w-100 d-flex justify-content-around flex-wrap",staticStyle:{"height":"40em","overflow-y":"auto","overflow-x":"hidden"}},[_c('b-table',{staticStyle:{"min-height":"20em"},attrs:{"sticky-header":"90%","striped":"","hover":"","responsive":"","items":_vm.roles,"fields":[
                            { key: 'index', label: 'N°' },
                            { key: 'titre', label: 'Titre', sortable: true },
                            { key: 'permissions', label: 'permissions', sortable: true },
                            { key: 'action', label: 'Actions' },
                        ]},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(_vm._s(++data.index))]}},{key:"cell(titre)",fn:function(data){return [_vm._v(_vm._s(data.item.libelleRole))]}},{key:"cell(permissions)",fn:function(data){return [_c('span',_vm._l((data.item.privileges),function(permission,i){return _c('b-badge',{key:i,staticClass:"m-1",attrs:{"pill":"","variant":"primary"}},[_vm._v(_vm._s(permission))])}),1)]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticStyle:{"display":"flex"}},[_c('b-button',{attrs:{"size":"sm","variant":"none"},on:{"click":function($event){$event.preventDefault();return _vm.roleDetails(row.item)}}},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"color":"blue"}})]),_c('b-button',{attrs:{"size":"sm","variant":"none"},on:{"click":function($event){$event.preventDefault();return _vm.editRole(row.item)}}},[_c('i',{staticClass:"ik ik-edit-2",staticStyle:{"color":"green"}})]),_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){$event.preventDefault();return _vm.deleteRole(row.item)}}},[_c('i',{staticClass:"fa fa-trash",staticStyle:{"color":"#f5365c"}})])],1)]}}])})],1)])],1)],1),_c('b-modal',{attrs:{"id":"roleForm","size":"lg","title":"Formulaire d'Ajout d'un role","hide-footer":"","no-close-on-backdrop":""}},[_c('role-form',{attrs:{"editRole":_vm.role,"action":_vm.action},on:{"roleAdded":_vm.addedRole,"roleEdited":_vm.roleEditionSuccessful}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"my-4 font-weight-bold"},[_vm._v("Liste des rôles")])])
}]

export { render, staticRenderFns }